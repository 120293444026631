/* eslint-env browser */

'use strict';

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import DashAppInner from './AppProvider.react';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import setStateEvent from './dispatchSetStateEvent';
import 'dash-renderer/lib/utils/clientsideFunctions';

const forcedConfig = {
    update_title: null,
};

const ConsumerContext = React.createContext();

function setWindowContext() {
    if (!window.__dashprivate_embedded_context) {
        window.__dashprivate_embedded_context = {context: ConsumerContext};
    }
}

class RenderDashInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {...this.props.context_value};

        window.addEventListener('__dashprivate_setStateEvent', (event) => {
            if (event.detail.config === this.props.config) {
                this.setState((prevState) => ({
                    ...prevState,
                    ...event.detail.value,
                }));
            }
        });
    }

    render() {
        return (
            <div>
                <ConsumerContext.Provider value={this.state}>
                    <DashAppInner
                        config={{...this.props.config, ...forcedConfig}}
                    />
                </ConsumerContext.Provider>
            </div>
        );
    }
}

RenderDashInner.propTypes = {
    config: PropTypes.object,
    context_value: PropTypes.object,
};

class DashApp extends React.Component {
    constructor(props) {
        super(props);
        setWindowContext();
    }
    render() {
        return (
            <div>
                <ConsumerContext.Provider value={this.props.value}>
                    <DashAppInner
                        config={{...this.props.config, ...forcedConfig}}
                    />
                </ConsumerContext.Provider>
            </div>
        );
    }
}

DashApp.propTypes = {
    config: PropTypes.object,
    value: PropTypes.any,
};

const dashConfigElement = document.getElementById('_dash-config');
const defaultConfiguration =
    dashConfigElement && JSON.parse(dashConfigElement.textContent);

/**
 * Render dash for compatibility with standalone dash.
 * @param {Object} configuration: Config for the embedded dash app. Normally the only
 *      necessary key is `url_base_pathname`, which should be the full URL of the app.
 * @param {string|HTMLElement} mountPoint: ID or reference to an empty div to render
 *      the app in.
 * @param {Object} context_value: Initial value of state to be shared with the app
 *
 * @returns {function} A setter for passing new state to the app.
 */
const renderDash = (
    configuration,
    mountPoint = 'react-entry-point',
    context_value = {}
) => {
    setWindowContext();
    const config = configuration || defaultConfiguration;

    const mountPointElement =
        typeof mountPoint === 'string'
            ? document.getElementById(mountPoint)
            : mountPoint;

    if (ReactDOM.createRoot) {
        const root = ReactDOM.createRoot(mountPointElement);
        root.render(
            <RenderDashInner context_value={context_value} config={config} />
        );
    } else {
        ReactDOM.render(
            <RenderDashInner context_value={context_value} config={config} />,
            mountPointElement
        );
    }

    return (value) => {
        setStateEvent(config, value);
    };
};

export {DashApp, renderDash};
