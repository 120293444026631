// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dash-absolute-debug {
    position: relative;
}

.dash-absolute-debug .dash-debug-menu,
.dash-absolute-debug .dash-callback-dag--container,
.dash-absolute-debug .dash-error-card,
.dash-absolute-debug .dash-debug-alert-label,
.dash-absolute-debug .dash-debug-menu__outer {
    position: absolute;
}

.dash-absolute-debug .dash-error-card--container {
    z-index: 10003;
}

._dash-loading {
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/debug.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;;;;;IAKI,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".dash-absolute-debug {\n    position: relative;\n}\n\n.dash-absolute-debug .dash-debug-menu,\n.dash-absolute-debug .dash-callback-dag--container,\n.dash-absolute-debug .dash-error-card,\n.dash-absolute-debug .dash-debug-alert-label,\n.dash-absolute-debug .dash-debug-menu__outer {\n    position: absolute;\n}\n\n.dash-absolute-debug .dash-error-card--container {\n    z-index: 10003;\n}\n\n._dash-loading {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
